
*{
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

nav {
  background: rgb(249 250 251);
  height: 4rem;
  margin-bottom: 1rem;
  position: static;

  .nav-container {
    width: 100%;
    height: 100%;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;

    div {
      // display: flex;
      // align-items: center;
      // flex-grow: 1;

      svg {
        height: 2rem;
        width: 2rem;
        margin-right: 0.3rem;
        color: #e22bba;
      }

      h1 {
        font-size: 1.5rem;
      }
    }

  
    
  }
}
