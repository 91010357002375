.card-content {
  min-height: 2.5rem;

  // display: flex;
  // justify-content: space-between;
  // align-items: flex-start;

  margin: 0.5rem 0 0;

  background: #fff;
  border-radius: 3px;
  border-bottom: 1px solid #ccc;

  position: relative;

  .card-title-container {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    font-size: 15px;

    transition: background-color 0.1s;

    p {
      padding: 0.7rem 0.5rem 0.5rem;

      max-width: 90%;
      overflow: hidden;
      word-wrap: break-word;
    }

    button {
      padding: 0.5rem 0.5rem 0 0;

      background: none;
      border: none;
      cursor: pointer;

      svg {
        opacity: 0;
        transition: color 0.2s, opacity 0.2s;

        &:hover {
          color: #e22bba;
        }
      }
    }

    &:hover {
      background-color: #cecece1f;

      svg {
        opacity: 1;
      }
    }
  }

  .input-card-title {
    width: 100%;
    height: 100%;

    padding: 0.7rem 0.5rem 0.5rem;

    resize: none;
    overflow: hidden;

    border-color: transparent;
    border-radius: 3px;
    font-size: 15px;

    transition: border-color 0.2s;

    &:focus {
      border: 1px solid #e22bba;
    }
  }
}
